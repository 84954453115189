// @mui
import { alpha, styled } from '@mui/material/styles';
import { ListItemText, ListItemButton, ListItemIcon, Button, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// config
import { ICON, NAVBAR } from '../../../config';

// ----------------------------------------------------------------------

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem',
})(({ activeRoot, activeSub, subItem, theme }) => ({
  ...theme.typography.body2,
  position: 'relative',
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  // activeRoot
  ...(activeRoot && {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  }),
  // activeSub
  ...(activeSub && {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
  }),
  // subItem
  ...(subItem && {
    height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
  }),
}));

export const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})(({ isCollapse, theme }) => ({
  whiteSpace: 'nowrap',
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
  }),
}));

export const ListItemIconStyle = styled(ListItemIcon)({
  width: ICON.NAVBAR_ITEM,
  height: ICON.NAVBAR_ITEM,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': { width: '100%', height: '100%' },
});

export const GoldBorderButton = styled(Button)({
  fontWeight: 700,
  border: 'unset',
  backgroundColor: 'transparent',
  backgroundImage: 'linear-gradient(180deg, #292929 0%, #020202 100%)',
  borderStyle: 'solid !important',
  borderColor: '#f2c556 !important',
});

export const GoldBorderLoadingButton = styled(LoadingButton)({
  fontWeight: 700,
  border: 'unset',
  backgroundColor: 'transparent',
  backgroundImage: 'linear-gradient(180deg, #292929 0%, #020202 100%)',
  borderStyle: 'solid !important',
  borderColor: '#f2c556 !important',
});

export const GoldBorderedWrapper = styled(Box)({
  backgroundColor: '#3A3A3A',
  borderWidth: '3px 3px 3px 3px',
  borderRadius: '10px 10px 10px 10px',
  borderStyle: 'solid',
  borderColor: '#EDC75B',
  boxShadow: '0px 0px 100px 0px #000000 inset',
  p: 2,
});

export const RBStylesByLocale = {
  en: {
    circleSubText: {
      fontSize: { xs: '2.5vw', sm: '2.5vw', md: '1.2vw', lg: '1.2vw', xl: '1vw' },
      fontWeight: 700,
    },
    button: {
      width: '100%',
      paddingY: { xs: 0.3, sm: 0.3, md: 0.3, lg: 0.3, xl: 0.3 },
      borderRadius: 5,
      fontSize: { xs: '2.7vw', sm: '2.5vw', md: '1.5vw', lg: '1.4vw', xl: '1.3vw' },
      fontWeight: 700,
    },
    dynContainer: { position: 'absolute', zIndex: 1, width: '100%', mt: { xs: '39%', sm: '40%', md: 0, lg: 0, xl: 0 } },
    circleContainer: {
      width: { xs: '80%', sm: '80%', md: '50%', lg: '50%', xl: '50%' },
      pt: { xs: 1, sm: 2, md: 2, lg: 2, xl: 3 },
      pb: { xs: '1vh', sm: 2, md: 0.3, lg: 1.5, xl: 2 },
    },
    mobileSocialIcons: { justifyContent: 'center', mb: { xs: 0.5, sm: 0.5, md: 0.8, lg: 0.8, xl: 0.8 } },
  },
  ms: {
    circleSubText: {
      fontSize: { xs: '2.5vw', sm: '2.5vw', md: '1vw', lg: '0.9vw', xl: '0.8vw' },
      fontWeight: 700,
    },
    button: {
      width: '100%',
      paddingY: { xs: 0.3, sm: 0.3, md: 0.3, lg: 0.3, xl: 0.3 },
      borderRadius: 5,
      fontSize: { xs: '2.5vw', sm: '2vw', md: '1vw', lg: '1.1vw', xl: '1.1vw' },
      fontWeight: 700,
    },
    dynContainer: { position: 'absolute', zIndex: 1, width: '100%', mt: { xs: '38%', sm: '40%', md: 0, lg: 0, xl: 0 } },
    circleContainer: {
      width: { xs: '80%', sm: '80%', md: '50%', lg: '50%', xl: '50%' },
      pt: { xs: 1, sm: 2, md: 2, lg: 2, xl: 3 },
      pb: { xs: '1vh', sm: 2, md: 0.3, lg: 1.5, xl: 2 },
    },
    mobileSocialIcons: { justifyContent: 'center', mb: { xs: 0.5, sm: 0.5, md: 0.8, lg: 0.8, xl: 0.8 } },
  },
  my: {
    circleSubText: {
      fontSize: { xs: '2vw', sm: '2vw', md: '1vw', lg: '1vw', xl: '1vw' },
      fontWeight: 700,
    },
    button: {
      width: '100%',
      paddingY: { xs: '0.8vh', sm: 1, md: 0.5, lg: 1, xl: 1 },
      borderRadius: 5,
      fontSize: { xs: '2vw', sm: '2vw', md: '1vw', lg: '0.9vw', xl: '0.9vw' },
      fontWeight: 700,
      height: '100%',
    },
    dynContainer: { position: 'absolute', zIndex: 1, width: '100%', mt: { xs: '32%', sm: '35%', md: 0, lg: 0, xl: 0 } },
    circleContainer: {
      width: { xs: '80%', sm: '80%', md: '50%', lg: '50%', xl: '50%' },
      pt: { xs: '2vw', sm: '2vw', md: 2, lg: 2, xl: 3 },
      pb: { xs: '4vw', sm: '4vw', md: 0.3, lg: 1.5, xl: 2 },
    },
    mobileSocialIcons: { justifyContent: 'center', mb: { xs: 1, sm: 1, md: 0.8, lg: 0.8, xl: 0.8 } },
  },
  th: {
    circleSubText: {
      fontSize: { xs: '2vw', sm: '2vw', md: '1vw', lg: '1vw', xl: '1vw' },
      fontWeight: 700,
    },
    button: {
      width: '100%',
      paddingY: { xs: '0.8vh', sm: 1, md: 0.5, lg: 1, xl: 1 },
      borderRadius: 5,
      fontSize: { xs: '2vw', sm: '2vw', md: '1vw', lg: '0.9vw', xl: '0.9vw' },
      fontWeight: 700,
      height: '100%',
    },
    dynContainer: { position: 'absolute', zIndex: 1, width: '100%', mt: { xs: '32%', sm: '35%', md: 0, lg: 0, xl: 0 } },
    circleContainer: {
      width: { xs: '80%', sm: '80%', md: '50%', lg: '50%', xl: '50%' },
      pt: { xs: '2vw', sm: '2vw', md: 2, lg: 2, xl: 3 },
      pb: { xs: '4vw', sm: '4vw', md: 0.3, lg: 1.5, xl: 2 },
    },
    mobileSocialIcons: { justifyContent: 'center', mb: { xs: 1, sm: 1, md: 0.8, lg: 0.8, xl: 0.8 } },
  },
  bn: {
    circleSubText: {
      fontSize: { xs: '2vw', sm: '2vw', md: '1vw', lg: '1vw', xl: '1vw' },
      fontWeight: 700,
    },
    button: {
      width: '100%',
      paddingY: { xs: '0.8vh', sm: 1, md: 0.5, lg: 1, xl: 1 },
      borderRadius: 5,
      fontSize: { xs: '2vw', sm: '2vw', md: '1vw', lg: '0.9vw', xl: '0.9vw' },
      fontWeight: 700,
      height: '100%',
    },
    dynContainer: { position: 'absolute', zIndex: 1, width: '100%', mt: { xs: '32%', sm: '35%', md: 0, lg: 0, xl: 0 } },
    circleContainer: {
      width: { xs: '80%', sm: '80%', md: '50%', lg: '50%', xl: '50%' },
      pt: { xs: '2vw', sm: '2vw', md: 2, lg: 2, xl: 3 },
      pb: { xs: '4vw', sm: '4vw', md: 0.3, lg: 1.5, xl: 2 },
    },
    mobileSocialIcons: { justifyContent: 'center', mb: { xs: 1, sm: 1, md: 0.8, lg: 0.8, xl: 0.8 } },
  },
  vn: {
    circleSubText: {
      fontSize: { xs: '1.5vw', sm: '1.4vw', md: '0.9vw', lg: '0.8vw', xl: '0.7vw' },
      fontWeight: 700,
    },
    button: {
      width: '100%',
      paddingY: { xs: 0.5, sm: 0.5, md: 0.6, lg: 0.8, xl: 0.8 },
      borderRadius: 5,
      fontSize: { xs: '2vw', sm: '2vw', md: '1vw', lg: '0.8vw', xl: '0.8vw' },
      fontWeight: 700,
    },
    dynContainer: { position: 'absolute', zIndex: 1, width: '100%', mt: { xs: '32%', sm: '35%', md: 0, lg: 0, xl: 0 } },
    circleContainer: {
      width: { xs: '80%', sm: '80%', md: '50%', lg: '50%', xl: '50%' },
      pt: { xs: 1, sm: 2, md: 2, lg: 2, xl: 3 },
      pb: { xs: '1vh', sm: 2, md: 0.3, lg: 1.5, xl: 2 },
    },
    mobileSocialIcons: { justifyContent: 'center', mb: { xs: 0.5, sm: 0.5, md: 0.8, lg: 0.8, xl: 0.8 } },
  },
};

export const LoginStylesByLocale = {
  en: {
    contactUs: {
      fontSize: { xs: '1.8rem', sm: '1.8rem', md: '1.8rem', lg: '1.8rem', xl: '1.8rem' },
      fontWeight: 700,
    },
  },
  my: {
    contactUs: {
      fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.2rem', lg: '1.5rem', xl: '1.5rem' },
      fontWeight: 700,
    },
  },
  ms: {
    contactUs: {
      fontSize: { xs: '1.8rem', sm: '1.8rem', md: '1.8rem', lg: '1.8rem', xl: '1.8rem' },
      fontWeight: 700,
    },
  },
  th: {
    contactUs: {
      fontSize: { xs: '1.8rem', sm: '1.8rem', md: '1.8rem', lg: '1.8rem', xl: '1.8rem' },
      fontWeight: 700,
    },
  },
  bn: {
    contactUs: {
      fontSize: { xs: '1.8rem', sm: '1.8rem', md: '1.8rem', lg: '1.8rem', xl: '1.8rem' },
      fontWeight: 700,
    },
  },
  vn: {
    contactUs: {
      fontSize: { xs: '1.8rem', sm: '1.8rem', md: '1.8rem', lg: '1.8rem', xl: '1.8rem' },
      fontWeight: 700,
    },
  },
};
