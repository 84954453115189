import React from 'react';
import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { Box, Stack, Toolbar, Typography } from '@mui/material';
import Image from '../../../components/Image';
import SocialIcons from '../../../sections/about/SocialIcons';

const HeaderSocial = (props) => (
    <Toolbar
      className="card-bg"
      sx={{
        minHeight: '100% !important',
        px: { lg: 5, md: 1, sm: 1, xs: 1 },
        py: 0.5,
      }}
    >
      {/* <Box
        component={m.div}
        animate={{ scale: [0.7, 1, 1, 1, 1, 1, 1, 0.7], rotate: [0, 5, -5, 5, -5, 5, -5, 0] }}
        transition={{
          type: 'spring',
          stiffness: 260,
          damping: 20,
          repeat: Infinity,
          repeatDelay: 1,
          duration: 1,
        }}
        sx={{ width: '23%' }}
      >
        <Image src={`${S3_ASSETS_URL}home/app-download1.webp`} />
      </Box>

      <Box
        sx={{
          borderColor: '#C99D43',
          borderStyle: 'solid',
          borderWidth: '0px 0px 0px 2px',
          margin: '0px -5px 0px 0px',
          padding: '5px 0px 5px 6px',
        }}
      >
        <Stack>
          <Typography className="gold-text" fontWeight={900} sx={{ fontSize: '12px' }}>
            ZK7 Game APP
          </Typography>
          <Typography sx={{ fontSize: '12px' }}>Download & Play Today</Typography>
        </Stack>
      </Box> */}

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ width: '80%', py: 0.5 }}>
        <SocialIcons
          GridContainerProps={{ sx: { justifyContent: 'end', width: '100%' }, spacing: 1 }}
          isFullRow={true}
        />
      </Box>
    </Toolbar>
  );

HeaderSocial.propTypes = {};

export default HeaderSocial;
