import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { AnimatePresence, m } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router';
import useResponsive from '../../../hooks/useResponsive';
import Image from '../../../components/Image';
import { varFade } from '../../../components/animate';
import useAuth from '../../../hooks/useAuth';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
import { S3_ASSETS_URL } from '../../../config';
import { appLocale } from '../../../utils/const';
import { useDispatch, useSelector } from '../../../redux/store';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';
import { openLogin } from '../../../redux/slices/authPages';

const MobileNav = () => {
  const { isAuthenticated } = useAuth();

  const dispatch = useDispatch();

  const location = useLocation();

  const isSm = useResponsive('down', 'md');

  const navigate = useNavigate();

  const openDeposit = () => {
    if (isAuthenticated) {
      dispatch(
        openTransactionsDialog({
          open: true,
          isDeposit: true,
        })
      );
    } else {
      dispatch(openLogin());
    }
  };

  const openWithdraw = () => {
    if (isAuthenticated) {
      dispatch(
        openTransactionsDialog({
          open: true,
          isDeposit: false,
        })
      );
    } else {
      dispatch(openLogin());
    }
  };

  if (!isSm) return <></>;

  return (
    <>
      <AnimatePresence>
        <Box component={m.div} {...varFade().inUp} sx={{ position: 'fixed', bottom: 0, mx: 1, zIndex: 2 }}>
          <Grid container>
            <Grid
              item
              xs={4}
              sx={{ display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'center' }}
            >
              <Image src={`${S3_ASSETS_URL}home/navigation/MobileMenuBg.webp`} />
              <Box
                sx={{ position: 'absolute', display: 'flex', width: '90%', justifyContent: 'space-between', top: 0 }}
              >
                <Box
                  sx={{ width: '50%' }}
                  onClick={() =>
                    navigate('/', {
                      state: {
                        toScroll: 'bonus',
                        isFromOtherPage: location.pathname.indexOf('home') === -1,
                      },
                    })
                  }
                >
                  <Image
                    sx={{ width: '100%', cursor: 'pointer' }}
                    src={`${S3_ASSETS_URL}home/navigation/${appLocale}/MobileNavi-2.png`}
                  />
                </Box>
                <Box
                  sx={{ width: '50%' }}
                  onClick={() => navigate('/')}
                >
                  <Image
                    sx={{ width: '100%', cursor: 'pointer' }}
                    src={`${S3_ASSETS_URL}home/navigation/${appLocale}/MobileNavi-3.png`}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
              <Image
                src={`${S3_ASSETS_URL}home/navigation/MobileNavi-1.png`}
                sx={{ cursor: 'pointer' }}
                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'center' }}
            >
              <Image src={`${S3_ASSETS_URL}home/navigation/MobileMenuBg.webp`} />
              <Box
                sx={{ position: 'absolute', display: 'flex', width: '90%', justifyContent: 'space-between', top: 0 }}
              >
                <Box sx={{ width: '50%' }}>
                  <Image
                    sx={{ width: '100%', cursor: 'pointer' }}
                    onClick={openDeposit}
                    src={`${S3_ASSETS_URL}home/navigation/${appLocale}/deposit-icon.png`}
                  />
                </Box>
                <Box sx={{ width: '50%' }}>
                  <Image
                    sx={{ width: '100%', cursor: 'pointer' }}
                    onClick={openWithdraw}
                    src={`${S3_ASSETS_URL}home/navigation/${appLocale}/withdraw-icon.png`}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </AnimatePresence>
    </>
  );
};

export default MobileNav;

export const MobileNavSpacer = () => {
  const isSm = useResponsive('down', 'md');

  if (!isSm) return <></>;

  return (
    <Grid container sx={{ display: 'flex', justifyContent: 'center', opacity: 0 }}>
      <Grid xs={4}>
        <Image
          src={`${S3_ASSETS_URL}home/navigation/MobileNavi-1.png`}
          sx={{ cursor: 'pointer' }}
          // onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
        />
      </Grid>
    </Grid>
  );
};
