import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import CustomPhoneInput from '../CustomPhoneInput';

// ----------------------------------------------------------------------

RHFPhonenumber.propTypes = {
  name: PropTypes.string,
};

export default function RHFPhonenumber({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        // <PhonenumberTextField {...field} fullWidth error={!!error} helperText={error?.message} {...other} />
        <PhoneInput
          {...field}
          {...other}
          international
          defaultCountry={process.env.REACT_APP_COUNTRY_CODE}
          inputComponent={CustomPhoneInput}
        />
      )}
    />
  );
}
