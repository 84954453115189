import { S3_ASSETS_URL } from '../config';

// export const appLocale = (() => {
//   const lsLocales = localStorage.getItem('i18nextLng') || 'en';
//   // if (lsLocales === 'ms') return 'my';
//   // if (lsLocales === 'my') return 'mm';
//   // Temporary th assets not yet avail
//   if (lsLocales === 'th') return 'en';
//   return lsLocales;
// })();

export const appLocale = (() => localStorage.getItem('i18nextLng') || 'en')();

const gameMenuFolder = `${S3_ASSETS_URL}home/game_menu/${appLocale ? `${appLocale}/` : ''}`;

export const GAME_CATEGS = [
  {
    type: 'IG',
    name: 'Instant Game',
    subname: 'Instant Game',
    btnImageUrl: `${gameMenuFolder}InstantGame-x.png`,
    btnImageMobile: `${gameMenuFolder}Mobile-Menu-InstantGame.png`,
    subIcon: `${S3_ASSETS_URL}home/Sub-Casino.webp`,
  },
  {
    type: 'LC',
    name: 'Casino',
    subname: 'Live Casino',
    btnImageUrl: `${gameMenuFolder}Casino-x.webp`,
    btnImageMobile: `${gameMenuFolder}Mobile-Menu-Casino.gif`,
    subIcon: `${S3_ASSETS_URL}home/Sub-Casino.webp`
  },
  {
    type: 'SL',
    name: 'Slots',
    subname: 'Slots',
    btnImageUrl: `${gameMenuFolder}Slot-x.webp`,
    btnImageMobile: `${gameMenuFolder}Mobile-Menu-Slot.gif`,
    subIcon: `${S3_ASSETS_URL}home/Sub-Slot.webp`
  },
  {
    type: 'SB',
    name: 'Sports',
    subname: 'Sports',
    btnImageUrl: `${gameMenuFolder}Sport-x.webp`,
    btnImageMobile: `${gameMenuFolder}Mobile-Menu-Sport.gif`,
    subIcon: `${S3_ASSETS_URL}home/Sub-Sport.webp`
  },
  {
    type: 'CG',
    name: 'Card Game',
    subname: 'Card Game',
    btnImageUrl: `${gameMenuFolder}cardx.webp`,
    btnImageMobile: `${gameMenuFolder}Mobile-Menu-Card1.webp`,
    subIcon: `${S3_ASSETS_URL}home/Sub-Card.webp`
  },
  {
    type: 'FH',
    name: 'Fishing',
    subname: 'Fishing',
    btnImageUrl: `${gameMenuFolder}fishing.webp`,
    btnImageMobile: `${gameMenuFolder}Mobile-Menu-Fishing.webp`,
    subIcon: `${S3_ASSETS_URL}home/Sub-Fish.webp`
  },
  {
    type: 'ES',
    name: 'E-Sports',
    subname: 'E-Sports',
    btnImageUrl: `${gameMenuFolder}Esports-x.webp`,
    btnImageMobile: `${gameMenuFolder}Mobile-Menu-Esports.webp`,
    subIcon: `${S3_ASSETS_URL}home/banners/MBanner-Play.webp`
  },
];

// Refer to public/loadNewTab.html
export const launchGameNewTabHtml = `
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Loading Game</title>
</head>
<style>
    body{
        background-image: url('https://zk7web.s3.ap-south-1.amazonaws.com/home/game-bg.png');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: 100% 100%;
        background-position: center;
    }
    .container {
        width: 100%;
        height: 100%;
        font-family: Roboto, sans-serif;
        font-size: 2rem;
        color: white;
        font-weight: 700;
        /* margin-top: 5rem; */
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .gold-text {
        background-image: linear-gradient(#eec85c, #a5732c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media (max-width: 767px) {
      /* Your styles go here */
      body {
        background-image: url('https://zk7web.s3.ap-south-1.amazonaws.com/home/game-bg-mobile.png');
      }
    }
</style>
<body>
    <div class="container gold-text">
        Loading game site...
    </div>
</body>
</html>
`