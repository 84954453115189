// ----------------------------------------------------------------------

export default function LoadingButton() {
  return {
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-text': {
            '& .MuiLoadingButton-startIconPendingStart': {
              marginLeft: 0,
            },
            '& .MuiLoadingButton-endIconPendingEnd': {
              marginRight: 0,
            },
          },
        },
        // outlined
        // outlinedInherit: {
        //   border: `unset`,
        //   backgroundColor: 'transparent',
        //   backgroundImage: 'linear-gradient(180deg, #292929 0%, #020202 100%)',
        //   borderStyle: 'solid',
        //   borderColor: '#f2c556',
        // },
      },
    },
  };
}
