import { Box, Skeleton } from '@mui/material';
import React from 'react';

const DepositWithdrawSkeleton = () => (
  <Box sx={{ width: '100%' }}>
    <Skeleton variant="text" width="100%" height={'10rem'} />
    <Skeleton variant="text" width="100%" height={'2rem'} />
    <Skeleton variant="text" width="100%" height={'2rem'} />
    <Skeleton variant="text" width="100%" height={'5rem'} />
    <Skeleton variant="text" width="100%" height={'5rem'} />
    <Skeleton variant="text" width="100%" height={'5rem'} />
  </Box>
);

export default DepositWithdrawSkeleton;
