import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Link,
  Container,
  Typography,
  Tooltip,
  DialogContent,
  ButtonGroup,
  Button,
  Stack,
  Grid,
} from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { RegisterForm } from '../../sections/auth/register';
import useTabs from '../../hooks/useTabs';
import RegisterPhoneNumber from '../../sections/auth/register/RegisterPhoneNumber';
import { GoldBorderButton, LoginStylesByLocale } from '../../components/nav-section/vertical/style';
import { useDispatch } from '../../redux/store';
import { closeRegister, openLogin } from '../../redux/slices/authPages';
import SocialIcons from '../../sections/about/SocialIcons';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

const ContentStyle = styled(Stack)(({ theme }) => ({
  // maxWidth: 480,
  marginRight: 'auto',
  marginLeft: 'auto',
  // minHeight: '100vh',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const dispatch = useDispatch();

  const { translate, currentLang } = useLocales();

  return (
    <ContentStyle spacing={4}>
      <Typography className="gold-text" variant="h4" fontWeight={900}>
        {translate('register')}
      </Typography>

      {/* <ButtonGroup>
        {registerSteps.map((x, idx) => (
          <Button
            key={idx}
            onClick={() => setCurrentTab(idx)}
            sx={{
              backgroundColor: (theme) =>
                idx.toString() === currentTab.toString() ? '#e7bd55' : theme.palette.primary.main,
              borderColor: 'black',
            }}
          />
        ))}
      </ButtonGroup> */}

      <RegisterForm />

      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="body2" sx={{ mr: 1 }}>
          {translate('already_have_an_account')}
        </Typography>
        <GoldBorderButton
          size="small"
          onClick={() => {
            dispatch(openLogin());
            dispatch(closeRegister());
          }}
        >
          <Typography className="gold-text" variant={'body2'} fontWeight={700}>
            {translate('login')}
          </Typography>
        </GoldBorderButton>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Stack sx={{ alignItems: 'center', width: '100%' }}>
          <Typography className="gold-text" sx={LoginStylesByLocale[currentLang?.value || 'en']?.contactUs}>
            {translate('contact_us')}
          </Typography>
          <Box sx={{ width: { xs: '70%', sm: '60%', md: '50%', lg: '50%', xl: '50%' } }}>
            <SocialIcons
              GridContainerProps={{ sx: { justifyContent: 'center' }, spacing: { xs: 0, sm: 0, md: 1, lg: 1, xl: 1 } }}
              isFullRow={true}
            />
          </Box>
        </Stack>
      </Box>
    </ContentStyle>
  );
}
