import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography, Stack, Grid } from '@mui/material';
// components
import Image from '../../components/Image';
// sections
import { ChangePasswordForm } from '../../sections/auth/change-password';
import SocialIcons from '../../sections/about/SocialIcons';
import useLocales from '../../hooks/useLocales';
import { LoginStylesByLocale } from '../../components/nav-section/vertical/style';

// ----------------------------------------------------------------------

const ContentStyle = styled(Stack)(({ theme }) => ({
  // maxWidth: 480,
  marginRight: 'auto',
  marginLeft: 'auto',
  // minHeight: '100vh',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
}));

// ----------------------------------------------------------------------

export default function ChangePassword() {
  const { translate, currentLang } = useLocales();

  return (
    <ContentStyle spacing={4}>
      <Typography className="gold-text" variant="h4" fontWeight={900}>
        {translate('change_password')}
      </Typography>

      <ChangePasswordForm />

      <Box sx={{ width: '100%' }}>
        <Stack sx={{ alignItems: 'center', width: '100%' }}>
          <Typography className="gold-text" sx={LoginStylesByLocale[currentLang?.value || 'en']?.contactUs}>
            {translate('contact_us')}
          </Typography>
          <Box sx={{ width: { xs: '70%', sm: '60%', md: '50%', lg: '50%', xl: '50%' } }}>
            <SocialIcons
              GridContainerProps={{ sx: { justifyContent: 'center' }, spacing: { xs: 0, sm: 0, md: 1, lg: 2, xl: 2 } }}
            />
          </Box>
        </Stack>
      </Box>
    </ContentStyle>
  );
}
