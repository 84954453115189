import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isOpenLogin: false,
  isOpenRegister: false,
  isOpenForgotPwd: false,
  isOpenChangePwd: false,
};

const slice = createSlice({
  name: 'authPages',
  initialState,
  reducers: {
    closeLogin(state) {
      state.isOpenLogin = false;
    },
    openLogin(state) {
      state.isOpenLogin = true;
    },
    closeRegister(state) {
      state.isOpenRegister = false;
    },
    openRegister(state) {
      state.isOpenRegister = true;
    },
    closeForgotPwd(state) {
      state.isOpenForgotPwd = false;
    },
    openForgotPwd(state) {
      state.isOpenForgotPwd = true;
    },
    closeChangePwd(state) {
      state.isOpenChangePwd = false;
    },
    openChangePwd(state) {
      state.isOpenChangePwd = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openLogin,
  openForgotPwd,
  openRegister,
  openChangePwd,
  closeLogin,
  closeRegister,
  closeForgotPwd,
  closeChangePwd,
} = slice.actions;
