import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isAddMemberBankDialog: {
    open: false,
    onClose: null,
  },
};

const slice = createSlice({
  name: 'addMemberBankDialog',
  initialState,
  reducers: {
    openAddMemberBankDialog(state, action) {
      state.isAddMemberBankDialog = {
        open: action?.payload?.open,
        onClose: action?.payload?.onClose,
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openAddMemberBankDialog } = slice.actions;
