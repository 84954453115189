// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/g';
const ROOTS_DASHBOARD = '/';
const ROOTS_GAMES = '/games';
const ROOTS_BET_HISTORY = '/bet-history';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  gHome: path(ROOTS_AUTH, '/home'),
};

export const PATH_GAMES = {
  root: ROOTS_GAMES,
};

export const PATH_BET_HISTORY = {
  root: ROOTS_BET_HISTORY,
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
};
