import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { Box, Button, Card, CircularProgress, Stack, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { GoldBorderButton } from '../../../components/nav-section/vertical/style';
import Image from '../../../components/Image';
import LanguagePopover from './LanguagePopover';
import useAuth from '../../../hooks/useAuth';
import AccountPopover from './AccountPopover';
import { useDispatch, useSelector } from '../../../redux/store';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
import { openLogin, openRegister } from '../../../redux/slices/authPages';
import { S3_ASSETS_URL } from '../../../config';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';
import useLocales from '../../../hooks/useLocales';
import NotificationsPopover from './NotificationsPopover';

const HeaderLogo = (props) => {
  const { isAuthenticated } = useAuth();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { memberBalance } = useSelector((x) => x.lookup);

  const { translate } = useLocales();

  return (
    <Toolbar
      className="gold-border-top-bottom"
      sx={{
        minHeight: '100% !important',
        px: { lg: 5, md: 1, sm: 1, xs: 1 },
        py: { sm: 1, xs: 1 },
        backgroundColor: 'black',
      }}
    >
      <Box sx={{ width: '25%' }}>
        <Image
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate('/')}
          src={`${S3_ASSETS_URL}home/zk7-logo-no-bg.webp`}
        />
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      <Box>
        <LanguagePopover />
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      <Box>
        {isAuthenticated ? (
          <Stack direction={'row'} spacing={1}>
            <Card
              className="gold-border-button"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 0.3,
                borderRadius: 0.8,
                px: 1,
              }}
              onClick={() =>
                memberBalance?.isLoading &&
                dispatch(
                  openTransactionsDialog({
                    open: true,
                    isDeposit: true,
                  })
                )
              }
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mr: 0.2 }}>
                {/* <Iconify icon={'tdesign:money'} sx={{ color: 'primary.main', mr: 0.1, fontSize: 15 }} /> */}
                <Typography fontSize={10} fontWeight={'bolder'}>
                  {memberBalance?.currencyCode || ''}
                </Typography>
              </Box>
              {memberBalance?.isLoading && !memberBalance?.displayBalance ? (
                <CircularProgress size={'1rem'} />
              ) : (
                <Typography fontSize={10} fontWeight={'bolder'}>
                  {memberBalance?.displayBalance || 0}
                </Typography>
              )}
            </Card>
            {/* <Image
              onClick={() => setOpenDepositWithdrawDialog({ open: true, isDeposit: true })}
              src={`https://zk7web.s3.ap-south-1.amazonaws.com/home/navigation/en/deposit-icon.png`}
              sx={{ height: '35px', cursor: 'pointer' }}
            />
            <Image
              onClick={() => setOpenDepositWithdrawDialog({ open: true, isDeposit: false })}
              src={`https://zk7web.s3.ap-south-1.amazonaws.com/home/navigation/en/withdraw-icon.png`}
              sx={{ width: '35px', cursor: 'pointer' }}
            /> */}
            <NotificationsPopover />
            <AccountPopover />
          </Stack>
        ) : (
          <Stack direction={'row'} spacing={0.5}>
            <GoldBorderButton
              onClick={() => dispatch(openLogin())}
              size="small"
              sx={{ fontSize: { xs: '2vw', sm: '2vw', md: '1vw', lg: '1vw', xl: '1vw' }, color: 'white', px: 3 }}
            >
              {translate('login')}
            </GoldBorderButton>
            <GoldBorderButton
              onClick={() => dispatch(openRegister())}
              size="small"
              sx={{ fontSize: { xs: '2vw', sm: '2vw', md: '1vw', lg: '1vw', xl: '1vw' }, px: 2.5 }}
            >
              <Box className="gold-text">{translate('register')}</Box>
            </GoldBorderButton>
          </Stack>
        )}
      </Box>
    </Toolbar>
  );
};

HeaderLogo.propTypes = {};

export default HeaderLogo;
