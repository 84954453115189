import { useState, useEffect } from 'react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Alert, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFPhonenumber, RHFTextField } from '../../../components/hook-form';
import { GoldBorderLoadingButton } from '../../../components/nav-section/vertical/style';
import conObj from '../../../utils/connection-assistant';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
  const isMountedRef = useIsMountedRef();

  const { translate } = useLocales();

  const [sentEmailMsg, setSentEmailMsg] = useState(null);

  const ForgotSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate('invalid_x', { x: translate('email') }))
      .required(translate('x_is_required', { x: translate('email') })),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(ForgotSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await conObj.post('forgot.json', {
        'user-email': data?.email,
      });

      if (response.data.data.status === 1) {
        setSentEmailMsg(response.data.data.msg);
      } else {
        throw new Error(response.data?.data?.msg || 'Server Error!');
      }
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      {!sentEmailMsg ? (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

            <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Typography variant="caption">{translate('please_enter_email')}</Typography>
            </Box>

            <RHFTextField name="email" label={translate('email')} />

            <GoldBorderLoadingButton fullWidth variant="outlined" size="large" type="submit" loading={isSubmitting}>
              {translate('submit')}
            </GoldBorderLoadingButton>
          </Stack>
        </FormProvider>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <Typography>{sentEmailMsg}</Typography>
        </Box>
      )}
    </Box>
  );
}
