import { useState, useEffect } from 'react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Alert, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import { useSnackbar } from 'notistack';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFPassword, RHFPhonenumber, RHFTextField } from '../../../components/hook-form';
import { GoldBorderLoadingButton } from '../../../components/nav-section/vertical/style';
import conObj, { showResponseError } from '../../../utils/connection-assistant';
import { useDispatch } from '../../../redux/store';
import { closeChangePwd } from '../../../redux/slices/authPages';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

export default function ChangePasswordForm() {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { translate } = useLocales();

  const ChangePwdSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(6, 'Current password must be at least 6 characters')
      .required('Current password is required'),
    newPassword: Yup.string().min(6, 'New Password must be at least 6 characters').required('New Password is required'),
    confirmNewPassword: Yup.string().when('newPassword', {
      is: (val) => !!val,
      then: Yup.string()
        .nullable()
        .oneOf([Yup.ref('newPassword')], "Password doesn't match")
        .min(6, 'Confirm New Password must be at least 6 characters')
        .required('Confirm New Password is required'),
    }),
  });

  const defaultValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePwdSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await conObj.post('password.json', {
        'user-current_password': data?.currentPassword,
        'user-new_password': data?.newPassword,
        'user-new_password_confirmation': data?.confirmNewPassword,
      });
      reset();
      dispatch(closeChangePwd());
      enqueueSnackbar('Password changed');
    } catch (error) {
      reset();
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <RHFPassword name={'currentPassword'} label={translate('current_password')} size="small" />

          <RHFPassword name={'newPassword'} label={translate('new_password')} size="small" />

          <RHFPassword name={'confirmNewPassword'} label={translate('confirm_new_password')} size="small" />

          <GoldBorderLoadingButton fullWidth variant="outlined" size="large" type="submit" loading={isSubmitting}>
            {translate('submit')}
          </GoldBorderLoadingButton>
        </Stack>
      </FormProvider>
    </Box>
  );
}
