import jwtDecode from 'jwt-decode';
import { differenceInDays, fromUnixTime, getTime } from 'date-fns';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import axios from './axios';
import conObj from './connection-assistant';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleReauth = async () => {
  const response = await conObj.get(`Anonymous/reauth`);

  const accessToken = response.data;

  setSession(accessToken);
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();

  const daysLeft = differenceInDays(fromUnixTime(exp), currentTime);

  // setTimeout have a limited duration so max it at five days
  if (daysLeft <= 5) {
    const timeLeft = getTime(fromUnixTime(exp)) - getTime(currentTime);

    clearTimeout(expiredTimer);

    expiredTimer = setTimeout(() => handleReauth(), timeLeft);
  }
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken); // ~5 days by minimals server
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userInfo');
    delete axios.defaults.headers.common.Authorization;
    window.location.replace('/');
  }
};

export { isValidToken, setSession };
