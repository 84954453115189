import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, Grid, Button } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import { GoldBorderButton, LoginStylesByLocale } from '../../components/nav-section/vertical/style';
import { useDispatch } from '../../redux/store';
import { closeLogin, openForgotPwd, openRegister } from '../../redux/slices/authPages';
import SocialIcons from '../../sections/about/SocialIcons';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

const ContentStyle = styled(Stack)(({ theme }) => ({
  marginRight: 'auto',
  marginLeft: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const dispatch = useDispatch();

  const { translate, currentLang } = useLocales();

  return (
    <ContentStyle spacing={4}>
      <Typography className="gold-text" variant="h4" fontWeight={900}>
        {translate('login')}
      </Typography>

      <LoginForm />

      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="body2" sx={{ mr: 1 }}>
          {translate('dont_have_account')}
        </Typography>
        <GoldBorderButton
          size="small"
          onClick={() => {
            dispatch(openRegister());
            dispatch(closeLogin());
          }}
        >
          <Typography className="gold-text" variant={'body2'} fontWeight={700}>
            {translate('register')}
          </Typography>
        </GoldBorderButton>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Stack sx={{ alignItems: 'center', width: '100%' }} spacing={1}>
          <Typography className="gold-text" sx={LoginStylesByLocale[currentLang?.value || 'en']?.contactUs}>
            {translate('contact_us')}
          </Typography>
          <Box sx={{ width: { xs: '70%', sm: '60%', md: '50%', lg: '50%', xl: '50%' } }}>
            <SocialIcons
              GridContainerProps={{ sx: { justifyContent: 'center' }, spacing: { xs: 0, sm: 0, md: 1, lg: 1, xl: 1 } }} isFullRow={true}
            />
          </Box>
        </Stack>
      </Box>
    </ContentStyle>
  );
}
