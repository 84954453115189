import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isOpenCall: false,
  isOpenHardware: false,
  openPageLoadingCount: 0,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // OPEN CALL
    openCall(state) {
      console.log('enter call');
      state.isOpenCall = true;
    },

    // CLOSE CALL
    closeCall(state) {
      console.log('enter call close');
      state.isOpenCall = false;
    },

    // OPEN HARDWARE
    openHardware(state) {
      state.isOpenHardware = true;
    },

    // CLOSE HARDWARE
    closeHardware(state) {
      state.isOpenHardware = false;
    },

    // OPEN PAGE LOADING SCREEN
    openLoadingScreen(state) {
      state.openPageLoadingCount += 1;
    },

    // CLOSE PAGE LOADING SCREEN, DO NOT FORGET TO CALL THIS AFTER OPEN AS IT WILL NOT CLOSE THE LOADING SCREEN
    closeLoadingScreen(state) {
      if (state.openPageLoadingCount > 0) state.openPageLoadingCount -= 1;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openCall, closeCall, openHardware, closeHardware, openLoadingScreen, closeLoadingScreen } =
  slice.actions;
