import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { Dialog, DialogTitle, DialogActions, IconButton, Slide } from '@mui/material';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

CustomModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  actions: PropTypes.node,
  onClose: PropTypes.func,
  hasClose: PropTypes.bool,
  children: PropTypes.node,
  isBackdropClosable: PropTypes.bool,
  hasTransition: PropTypes.bool,
  paperPropsSx: PropTypes.object,
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function CustomModal({
  children,
  title = '',
  actions,
  open,
  onClose,
  hasClose = true,
  isBackdropClosable = true,
  hasTransition = false,
  paperPropsSx,
  ...other
}) {
  const handleClose = (e, r) => {
    if (!isBackdropClosable && r === 'backdropClick') {
      return false;
    }

    if (!isBackdropClosable && r === 'escapeKeyDown') {
      return false;
    }

    onClose(e, r);
    return true;
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      scroll={'body'}
      {...(hasTransition ? { TransitionComponent: Transition } : null)}
      {...other}
      transitionDuration={{ enter: 100, exit: 0 }}
      PaperProps={{
        sx: {
          backgroundColor: 'transparent !important',
          backgroundImage: 'linear-gradient(180deg, #292929 0%, #020202 100%) !important',
          borderStyle: 'solid !important',
          borderColor: '#f2c556 !important',
          boxShadow: '0px 0px 5px 0.5px rgba(242,197,86,0.8)',
          ...paperPropsSx,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        },
      }}
    >
      {hasClose && (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            right: { sm: 8, xs: 0 },
            top: { sm: 8, xs: 0 },
            zIndex: 1,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon={'ep:close'} />
        </IconButton>
      )}
      {title && <DialogTitle>{title}</DialogTitle>}
      {children}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
}
