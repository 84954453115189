import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedImage: null,
}

const confirmSlice = createSlice({
    name: "imagePreview",
    initialState,
    reducers: {
        setSelectedImage(state, action) {
            state.selectedImage = action.payload;
        },
        unSelectImage(state) {
            state.selectedImage = null;
        },
    }
});

const {actions, reducer} = confirmSlice;

// Reducer
export default reducer;

// Actions
export const {setSelectedImage, unSelectImage} = actions;
