// @mui
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
  DialogContent,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { useDispatch, useSelector } from '../redux/store';
import { resetConfirmState, setLoading, setOpen } from '../redux/slices/confirm';
import Iconify from './Iconify';
import useLocales from '../hooks/useLocales';
// import { resetConfirmState } from '../redux/slices/confirm';

// ----------------------------------------------------------------------

export default function CustomConfirm() {
  const theme = useTheme();

  const { isLoading, open, title, subTitle, onConfirm, onCancel, isInfo, isDanger } = useSelector(
    (state) => state.confirm
  );

  const { translate } = useLocales();

  const dispatch = useDispatch();

  useEffect(() => () => dispatch(resetConfirmState()), []);

  const handleCancel = () => {
    if (onCancel && typeof onCancel === 'function') {
      onCancel();
    }
    dispatch(setOpen(false));
    setTimeout(() => dispatch(resetConfirmState()), 500);
  };

  const handleConfirm = async () => {
    if (onConfirm && typeof onConfirm === 'function') {
      dispatch(setLoading(true));
      await onConfirm();
      dispatch(setLoading(false));
    }

    dispatch(setOpen(false));
    setTimeout(() => dispatch(resetConfirmState()), 500);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleCancel}
      sx={isDanger ? { backgroundColor: theme.palette.error.main } : {}}
      PaperProps={{
        sx: {
          backgroundColor: 'transparent !important',
          backgroundImage: 'linear-gradient(180deg, #292929 0%, #020202 100%) !important',
          borderStyle: 'solid !important',
          borderColor: '#f2c556 !important',
          boxShadow: '0px 0px 5px 0.5px rgba(242,197,86,0.8)',
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={() => handleCancel()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          zIndex: 1,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon={'ep:close'} />
      </IconButton>
      <DialogTitle>
        <Typography fontWeight={'bold'}>{title}</Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: 1 }}>
        <Typography component={'div'} variant="body2">
          {subTitle}
        </Typography>
      </DialogContent>
      <DialogActions>
        {!isInfo && (
          <Button variant="outlined" color="inherit" onClick={handleCancel}>
            {translate('No')}
          </Button>
        )}

        <LoadingButton
          type="submit"
          variant="contained"
          color={isDanger ? 'error' : 'primary'}
          loading={isLoading}
          disabled={isLoading}
          onClick={handleConfirm}
        >
          {translate(isInfo ? 'Ok' : 'Yes')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
